@use "@angular/material" as mat;
@import "src/theme.scss";
@include mat.core();

$blue-highlight: #00539b;

$ama-calculator-primary: mat.define-palette($primary-palette);
$ama-calculator-accent: mat.define-palette($primary-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ama-calculator-warn: mat.define-palette(mat.$red-palette);
$mat-ripple-background: transparent;
$ama-calculator-theme: mat.define-light-theme(
  (
    color: (
      primary: $ama-calculator-primary,
      accent: $ama-calculator-accent,
      warn: $ama-calculator-warn,
    ),
  )
);

// Screen breakpoints - Mobile first approach
//Tablet landscape
$breakpoint-small: 768px;
//Tablet portrait
$breakpoint-medium: 1024px;
//Desktop
$breakpoint-large: 1200px;

@include mat.all-component-themes($ama-calculator-theme);

// Mobile first approach
@mixin respond($viewport) {
  @if $viewport == small {
    @media only screen and (min-width: $breakpoint-small) {
      @content;
    }
  } @else if $viewport == medium {
    @media only screen and (min-width: $breakpoint-medium) {
      @content;
    }
  } @else if $viewport == large {
    @media only screen and (min-width: $breakpoint-large) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $viewport) {
      @content;
    }
  }
}


h3, ul {
  margin: 0 0 5px !important;

}

 

p{
  margin: 0 0 10px !important;
}


.info {
  display: flex;
  img {
    margin-left: 5px;
    width: 20px;
  }
  img:hover {
    cursor: pointer;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.72);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb(66, 134, 244);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: rgb(66, 134, 244);
  background-color: rgb(66, 134, 244);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(255, 37, 37, 0.26);
}

.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 0 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 65vw;
  font-size: 10pt;
  font-weight: 500;
  padding-left: 0 !important;
}

.mdc-checkbox {
  margin-bottom: auto !important;
}

a {
  color: $blue-highlight;
  font-weight: 600;
}
a:hover {
  cursor: pointer;
}
form {
  display: flex;
  flex-wrap: wrap;
  .mat-mdc-form-field {
    color: gray;
  }

  mat-form-field {
    margin: 5px 10px 10px 0;
  }
  label,
  h3,
  .break {
    flex-basis: 100%;
  }
  label {
    font-weight: 700;
  }
  mat-label {
    font-weight: 500;
    font-size: smaller;
  }
  button {
    flex-basis: 180px;
    margin-left: auto;
  }
  mat-radio-group {
    margin-bottom: 25px;
    label {
      font-weight: 500;
    }
  }
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: center;
    line-height: 0;
    pointer-events: auto;
    position: relative;
    z-index: 1;
    margin: 10px;
    color: gray;
  }
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.panel_header {
  transition: 0s;
  display: flex;
  flex-direction: row;
  gap: 10px;
  img {
    float: left;
    width: 50px;
    height: 50px;
    object-fit: scale-down;
  }
  h1 {
    font-size: 1.5rem;
    color: #00539b;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .welcome__italics {
      font-size: 2rem;
      color: #00539b;
      font-family: Brush Script MT;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

@media screen and (max-width: 600px) {
  mat-error {
    font-size: smaller;
    width: 90vw;
  }
  .panel_header {
    gap: 10px;
    h1 {
      font-size: 1rem;
      .welcome__italics {
        font-size: 1.5rem;
      }
    }
  }
  form {
    mat-form-field {
      margin-bottom: 0;
      margin-top: 5px;
      margin-left: 0;
    }
    label,
    h3,
    .break {
      flex-basis: 100%;
    }
    label {
      font-weight: 700;
    }
    mat-label {
      font-weight: 500;
      font-size: smaller;
    }
    button {
      flex-basis: 180px;
      margin-left: auto;
    }
    mat-radio-group {
      margin-bottom: 25px;
      label {
        font-weight: 500;
      }
    }
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      align-self: center;
      line-height: 0;
      pointer-events: auto;
      position: relative;
      z-index: 1;
      margin: 10px;
      color: gray;
    }
  }
}

h3 {
  color: $blue-highlight;
  font-size: 20px !important;
}

h2 {
  color: $blue-highlight;
  font-size: 25px !important;
}

.blue-header {
  font-size: 19px;
  text-align: center;
  color: #00539b;
  font-weight: 400;
}

.color-blue {
  color: $blue-highlight;
}

html,
body {
  height: 95%;
  margin: 0;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dialog-image-wrapper {
  width: 100%;
  position: relative;
  float: right;
  display: flex;
  justify-content: center;
  img {
    width: 20vw;
    padding: 20px;
    min-width: 275px;
  }
}

.print {
  display: none !important;
}

@media print {
  @page {
    size: A4 portrait;
  }
  @page :left {
    margin: 0.2cm !important;
  }
  @page :right {
    margin: 0.2cm !important;
  }

  @page :top {
    margin: 0.5cm;
  }
  .header {
    print-color-adjust: exact;
  }
  svg {
    display: none !important;
  }
  .mdc-floating-label--required:not(
      .mdc-floating-label--hide-required-marker
    )::after {
    content: unset !important;
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
    margin-bottom: -36px !important;
  }
  app-income-table {
    margin-top: 50px !important;
  }
  mat-expansion-panel-header {
    padding: 0 !important;
  }
  li {
    padding: 0 !important;
  }
  .info {
    img {
      display: none;
    }
  }
  .page-break-after {
    page-break-after: always !important;
  }

  .page-break-after-before {
    page-break-before: always !important;
  }
  mat-form-field {
    margin-bottom: -10px !important;
    margin-top: 3px !important;
  }
  app-comparison-results,
  app-welcome,
  app-personal-data,
  app-statement-data,
  app-assumptions,
  app-comparison-results {
    margin-top: 50px !important;
    print-color-adjust: exact;
  }

  .print {
    display: block !important;
  }
  .noprint {
    display: none !important;
  }

  button {
    visibility: hidden;
  }

  .noprint {
    display: none;
  }
}
